import { render, staticRenderFns } from "./BoxwrapToolbarItem.vue?vue&type=template&id=44be11e8&"
import script from "./BoxwrapToolbarItem.vue?vue&type=script&lang=js&"
export * from "./BoxwrapToolbarItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports